<template>
  <div class="flix-list-group">
    <div class="flix-list-group-item">
      <div class="flix-pull-right">
        <slot name="date">{{ $t('message.noDate') }}</slot>
      </div>
      <div class="flix-html-strong flix-text-strong">
        <slot name="title">{{ $t('message.noTitle') }}</slot>
      </div>
      <hr class="flix-html-hr"/>
      <flixIcon :id="'comments'" /> <slot name="text"></slot>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-html-hr
    margin: 12px 0
</style>
